import Vue from 'vue';
import VueRouter from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import DashboardLayout from '../layouts/DashboardLayout';
import isAuthenticatedGuard from '@/router/auth-guard';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login',
		name: 'ToLogin',
	},
	{
		path: '/',
		component: DefaultLayout,
		name: 'DefaultLayout',
		children: [
			{
				path: '/login',
				name: 'Login',
				component: () =>
					import(/* webpackChunkName: "login" */ '../views/Login.vue'),
			},
			{
				path: '/login/resetpassword',
				name: 'ResetPassword',
				component: () =>
					import(
						/* webpackChunkName: "ResetPassword" */ '../views/account/ResetPassword.vue'
					),
			},
			{
				path: '/recover/:token',
				name: 'RecoverPassword',
				component: () =>
					import(
						/* webpackChunkName: "RecoverPassword" */ '../views/account/RecoverPassword.vue'
					),
			},
		],
	},
	{
		path: '/',
		component: DashboardLayout,
		name: 'Home',
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
			},
			{
				path: '/dashboard/contract',
				name: 'ContractHome',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractHome" */ '@/views/dashboard/contract/home/ContractHomeView.vue'
					),
			},
			{
				path: '/dashboard/contract/search',
				name: 'ContractSearch',
				meta: { moduleName: 'Contrato', pageName: 'buscarContrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractSearch" */ '@/views/dashboard/contract/search/ContractSearchView.vue'
					),
			},
			{
				path: '/dashboard/contract/create',
				name: 'CreateContract',
				meta: { moduleName: 'Contrato', pageName: 'crearContrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "CreateContract" */ '@/views/dashboard/contract/create/CreateContractView.vue'
					),
			},
			{
				path: '/dashboard/contract/report',
				name: 'ContractReport',
				meta: { moduleName: 'Contrato', pageName: 'descargarReporte' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractReport" */ '@/views/dashboard/contract/report/ContractReportView.vue'
					),
			},
			{
				path: '/dashboard/contract/notification',
				name: 'ContractNotification',
				meta: { moduleName: 'Contrato', pageName: 'listarNotificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractNotification" */ '@/views/dashboard/contract/notification/ContractNotificationView.vue'
					),
			},
			{
				path: '/dashboard/contract/read/:documentId',
				name: 'documentShow',
				meta: { moduleName: 'Contrato', pageName: 'mostrarContrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ReadContract" */ '@/views/dashboard/contract/read/ReadContractView.vue'
					),
			},
			{
				path: '/dashboard/contract/download-contracts',
				name: 'DownloadContracts',
				// meta: { moduleName: 'Contrato', pageName: 'listarNotificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractNotification" */ '@/views/dashboard/contract/downloadContract/DownloadContractView.vue'
					),
			},
			{
				path: '/dashboard/contract/agreement/:agreementId',
				name: 'ContractReadAgreement',
				meta: { moduleName: 'Contrato', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractReadAgreement" */ '@/views/dashboard/agreement/read/ReadAgreementByLegal.vue'
					),
			},
			{
				path: '/dashboard/contract/agreement/:agreementId/editor',
				name: 'ContractAgreementEditor',
				meta: { moduleName: 'Contrato', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractAgreementEditor" */ '@/views/dashboard/agreement/readDocumentByLegal/ReadDocument.vue'
					),
			},
			{
				path: '/dashboard/contract/agreement/:agreementId/observations',
				name: 'ContractAgreementObservations',
				meta: { moduleName: 'Contrato', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractAgreementObservations" */ '@/views/dashboard/contract/agreement/editor/AgreementEditor.vue'
					),
			},
			{
				path: '/dashboard/contract/agreement/:documentId/document',
				name: 'ContractAgreementDocument',
				meta: { moduleName: 'Contrato', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ContractAgreementDocument" */ '@/views/dashboard/contract/PrecontractTemplate/PrecontractTemplateView.vue'
					),
			},
			{
				path: '/dashboard/supply',
				name: 'AgreementHome',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AgreementHome" */ '@/views/dashboard/agreement/home/AgreementHomeView.vue'
					),
			},
			{
				path: '/dashboard/supply/create',
				name: 'CreateAgreement',
				meta: { moduleName: 'abastecimiento', pageName: 'crearPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "CreateAgreement" */ '@/views/dashboard/agreement/create/CreateContract.vue'
					),
			},
			{
				path: '/dashboard/supply/search',
				name: 'SearchAgreement',
				meta: { moduleName: 'abastecimiento', pageName: 'buscarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SearchAgreementx" */ '@/views/dashboard/agreement/search/SearchAgreement.vue'
					),
			},
			{
				path: '/dashboard/supply/report',
				name: 'AgreementReport',
				meta: { moduleName: 'abastecimiento', pageName: 'reportePrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AgreementReport" */ '@/views/dashboard/agreement/report/AgreementReportView.vue'
					),
			},
			{
				path: '/dashboard/supply/notification',
				name: 'AgreementNotification',
				meta: { moduleName: 'abastecimiento', pageName: 'listarNotificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AgreementNotification" */ '@/views/dashboard/agreement/notification/AgreementNotificationView.vue'
					),
			},
			{
				path: '/dashboard/supply/agreement-type',
				name: 'AgreementTypeList',
				meta: { moduleName: 'abastecimiento', pageName: 'listaTiposDocumento' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AgreementTypeList" */ '@/views/dashboard/agreement/agreementType/AgreementTypeList/index.vue'
					),
			},
			{
				path: '/dashboard/supply/agreement-type/create',
				name: 'AgreementTypeCreate',
				meta: { moduleName: 'abastecimiento', pageName: 'crearTipoDocumento' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AgreementTypeCreate" */ '@/views/dashboard/agreement/agreementType/AgreementTypeCreate/index.vue'
					),
			},
			{
				path: '/dashboard/supply/agreement-type/:agreementTypeId/edit',
				name: 'AgreementTypeEdit',
				meta: { moduleName: 'abastecimiento', pageName: 'editarTipoDocumento' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AgreementTypeEdit" */ '@/views/dashboard/agreement/agreementType/AgreementTypeEdit/index.vue'
					),
			},
			{
				path: '/dashboard/supply/:agreementId',
				name: 'ReadAgreement',
				meta: { moduleName: 'abastecimiento', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ReadAgreement" */ '@/views/dashboard/agreement/read/ReadAgreement.vue'
					),
			},
			{
				path: '/dashboard/supply/:agreementId/editor',
				name: 'AgreementEditor',
				meta: { moduleName: 'abastecimiento', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "AgreementEditor" */ '@/views/dashboard/agreement/editor/AgreementEditor.vue'
					),
			},
			{
				path: '/dashboard/supply/:documentId/document',
				name: 'ReadDocument',
				meta: { moduleName: 'abastecimiento' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ReadDocument" */ '@/views/dashboard/agreement/readDocument/ReadDocument.vue'
					),
			},
			{
				path: '/dashboard/supply/:agreementId/precontract',
				name: 'ReadPrecontract',
				meta: { moduleName: 'abastecimiento', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ReadPrecontract" */ '@/views/dashboard/agreement/readDocumentByLegal/ReadDocument.vue'
					),
			},
			{
				path: '/dashboard/supplier',
				name: 'SupplierHome',
				//meta: { moduleName: 'abastecimiento', pageName: 'listarNotificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SupplierHome" */ '@/views/dashboard/supplier/SupplierHome/SupplierHomeView.vue'
					),
			},
			{
				path: '/dashboard/supplier/notification',
				name: 'SupplierNotification',
				//meta: { moduleName: 'abastecimiento', pageName: 'listarNotificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SupplierNotification" */ '@/views/dashboard/supplier/SupplierNotifications/SupplierNotificationsView.vue'
					),
			},
			{
				path: '/dashboard/supplier/precontract/:agreementId',
				name: 'SupplierPrecontractDetail',
				//meta: { moduleName: 'abastecimiento', pageName: 'listarNotificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SupplierPrecontractDetail" */ '@/views/dashboard/supplier/PrecontractDetail/PrecontractDetailView.vue'
					),
			},
			{
				path: '/dashboard/supplier/precontract/:agreementId/document',
				name: 'SupplierReadPrecontract',
				//meta: { moduleName: 'abastecimiento', pageName: 'listarNotificaciones' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SupplierReadPrecontract" */ '@/views/dashboard/supplier/SupplierDocument/SupplierDocument.vue'
					),
			},
			{
				path: '/dashboard/supplier/precontract/:documentId/template',
				name: 'SupplierPrecontractTemplate',
				meta: { moduleName: 'Contrato', pageName: 'mostrarPrecontrato' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "SupplierPrecontractTemplate" */ '@/views/dashboard/supplier/PrecontractTemplate/PrecontractTemplateView.vue'
					),
			},
			{
				path: '/dashboard/file/read',
				name: 'ReadFile',
				meta: { moduleName: 'Archivo' },
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "ReadFile" */ '@/views/dashboard/file/read/FileReadView.vue'
					),
			},
			{
				path: '/dashboard/user/permissions',
				name: 'UserPermissions',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "UserPermissions" */ '@/views/dashboard/user/permissions/permissions.vue'
					),
			},
			{
				path: '/dashboard/editor/ckeditor',
				name: 'CkeditorBox',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(
						/* webpackChunkName: "CkeditorBox" */ '@/views/dashboard/editor/CkeditorBox.vue'
					),
			},
			// {
			// 	path: '/dashboard/user/password',
			// 	name: 'UserChangePassword',
			// 	beforeEnter: isAuthenticatedGuard,
			// 	component: () =>
			// 		import(
			// 			/* webpackChunkName: "UserChangePassword" */ '@/views/dashboard/user/password/changePassword.vue'
			// 		),
			// },
			{
				path: '/401',
				name: 'NotAuthorized',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotAuthorized" */ '../views/401.vue'),
			},
			{
				path: '/:pathMatch(.*)*',
				name: 'NotFound',
				beforeEnter: isAuthenticatedGuard,
				component: () =>
					import(/* webpackChunkName: "NotFound" */ '../views/404.vue'),
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
