import { http } from '../../../api/http-common';

// const saveProcedure = async (payload) => {
// 	try {
// 		const { data } = await http.post('procedure', payload);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const loadProcedure = async (payload) => {
// 	try {
// 		const { data } = await http.post('procedure/load', payload);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const updateProcedure = async (payload) => {
// 	try {
// 		const { procedure, procedureId } = payload;
// 		const { data } = await http.put(`procedure/${procedureId}`, procedure);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const deleteProcedure = async (payload) => {
// 	try {
// 		const { procedureId } = payload;
// 		const { data } = await http.delete(`procedure/${procedureId}`);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const listProcedures = async (params) => {
// 	try {
// 		const { data } = await http.get('procedure', { params });
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const saveAssignment = async (payload) => {
// 	try {
// 		const { data } = await http.post('assignment', payload);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const updateAssignment = async (payload) => {
// 	try {
// 		const { assignment, assignmentId } = payload;
// 		const { data } = await http.put(`assignment/${assignmentId}`, assignment);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const listAssignment = async (params) => {
// 	try {
// 		const { data } = await http.get('assignment', { params });
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

const saveOwner = async (payload) => {
	try {
		const { data } = await http.post('owner', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listOwners = async (params) => {
	try {
		const { data } = await http.get('owner', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { saveOwner, listOwners };
