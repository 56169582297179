import Vue from 'vue';
import Vuex from 'vuex';
// MODULES
import security from './modules/security';
import auth from './modules/auth';
import user from './modules/user';
import contract from './modules/contract';
import owner from './modules/owner';
import document from './modules/document';
import responsible from './modules/responsible';
import report from './modules/report';
import agreement from './modules/agreement';
import agreementNotification from './modules/agreementNotification';
import agreementObservation from './modules/agreementObservation';
import agreementDocument from './modules/agreementDocument';

Vue.use(Vuex);

export default new Vuex.Store({
	// TODO: strict: true,
	state: {},
	mutations: {},
	actions: {},
	modules: {
		security,
		auth,
		user,
		contract,
		owner,
		document,
		responsible,
		report,
		agreement,
		agreementNotification,
		agreementObservation,
		agreementDocument,
	},
});
