import { http } from '@/store/api/http-common.js';

const createAgreementObservation = async (payload) => {
	try {
		const { data } = await http.post('agreement-notification-observation', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listAgreementObservation = async (params) => {
	try {
		const { data } = await http.get('agreement-notification-observation', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteAgreementObservation = async ({ observationId }) => {
	try {
		const { data } = await http.delete(
			`agreement-notification-observation/${observationId}`
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const solvedAgreementObservation = async ({ observationId }) => {
	try {
		const { data } = await http.put(
			`agreement-notification-observation/${observationId}/solved`
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * AgreementAnswer
 */

const createAgreementAnswer = async (payload) => {
	try {
		const { data } = await http.post('agreement-notification-answer', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listAgreementAnswer = async (params) => {
	try {
		const { data } = await http.get('agreement-notification-answer', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteAgreementAnswer = async ({ answerId }) => {
	try {
		const { data } = await http.delete(`agreement-notification-answer/${answerId}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	createAgreementObservation,
	listAgreementObservation,
	deleteAgreementObservation,
	solvedAgreementObservation,
	createAgreementAnswer,
	listAgreementAnswer,
	deleteAgreementAnswer,
};
