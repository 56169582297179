import { http } from '@/store/api/http-common.js';

const createAgreementDocument = async (payload) => {
	try {
		const { data } = await http.post('agreement-document', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listAgreementDocument = async (params) => {
	try {
		const { data } = await http.get('agreement-document', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteAgreementDocument = async ({ documentId }) => {
	try {
		const { data } = await http.delete(`agreement-document/${documentId}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { createAgreementDocument, listAgreementDocument, deleteAgreementDocument };
