import * as documentService from './service/document.service';

const state = {
	documentTypes: [],
	template: null,
};
const mutations = {
	SET_DOCUMENT_TYPES(state, payload) {
		state.documentTypes = payload || null;
	},
	SET_TEMPLATE(state, payload) {
		state.template = payload || null;
	},
};

const actions = {
	async listDocumentType({ commit }, data) {
		try {
			commit('SET_DOCUMENT_TYPES', null);
			const response = await documentService.listDocumentType(data);
			commit('SET_DOCUMENT_TYPES', response?.documentTypes || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getTemplate({ commit }, id) {
		try {
			commit('SET_TEMPLATE', null);
			const response = await documentService.getTemplate(id);
			commit('SET_TEMPLATE', response?.data || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateSheetAddendum(context, data) {
		try {			
			const response = await documentService.updateSheetAddendum(data);			
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	// cleanAssignment({ commit }) {
	// 	commit('SET_ASSIGNMENTS', null);
	// },
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
