import * as observation from './service/observation.service';

const state = {
	agreementObservation: {},
	agreementObservations: [],
	agreementObservationsPagination: null,

	agreementAnswer: {},
	agreementAnswers: [],
	agreementAnswersPagination: null,
};
const mutations = {
	SET_AGREEMENT_OBSERVATION(state, payload) {
		state.agreementObservation = payload || null;
	},
	SET_AGREEMENT_OBSERVATIONS(state, payload) {
		state.agreementObservations = payload || null;
	},
	SET_AGREEMENT_OBSERVATIONS_PAGINATION(state, payload) {
		state.agreementObservationsPagination = payload || null;
	},

	SET_AGREEMENT_ANSWER(state, payload) {
		state.agreementAnswer = payload || null;
	},
	SET_AGREEMENT_ANSWERS(state, payload) {
		state.agreementAnswers = payload || null;
	},
	SET_AGREEMENT_ANSWERS_PAGINATION(state, payload) {
		state.agreementAnswersPagination = payload || null;
	},
};

const actions = {
	async createAgreementObservation(context, data) {
		try {
			const response = await observation.createAgreementObservation(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAgreementObservation({ commit }, data) {
		try {
			const response = await observation.listAgreementObservation(data);
			commit('SET_AGREEMENT_OBSERVATIONS', response?.data || []);
			commit('SET_AGREEMENT_OBSERVATIONS_PAGINATION', response?.pagination || null);
			return { ok: true, response };
		} catch (error) {
			commit('SET_AGREEMENT_OBSERVATIONS', []);
			commit('SET_AGREEMENT_OBSERVATIONS_PAGINATION', null);
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteAgreementObservation(context, data) {
		try {
			const response = await observation.deleteAgreementObservation(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async solvedAgreementObservation(context, data) {
		try {
			const response = await observation.solvedAgreementObservation(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	/**
	 * AgreementAnswer
	 */

	async createAgreementAnswer(context, data) {
		try {
			const response = await observation.createAgreementAnswer(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAgreementAnswer(context, data) {
		try {
			const response = await observation.listAgreementAnswer(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteAgreementAnswer(context, data) {
		try {
			const response = await observation.deleteAgreementAnswer(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanAgreementObservations({ commit }) {
		commit('SET_AGREEMENT_OBSERVATIONS', []);
	},
	cleanAgreementAnswer({ commit }) {
		commit('SET_AGREEMENT_ANSWERS', []);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
