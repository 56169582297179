import * as responsibleService from './service/responsible.service';

const state = {
	responsible: [],
};
const mutations = {
	SET_RESPONSIBLES(state, payload) {
		state.responsible = payload || null;
	},
};

const actions = {
	async saveResponsible(context, data) {
		try {
			const response = await responsibleService.saveResponsible(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateResponsible(context, data) {
		try {
			const response = await responsibleService.updateResponsible(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteResponsible(context, data) {
		try {
			const response = await responsibleService.deleteResponsible(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanResponsibles({ commit }) {
		commit('SET_RESPONSIBLES', null);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
