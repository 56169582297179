import * as notificationService from './service/agreementNotification.service';

const state = {
	agreementNotification: {},
	agreementNotifications: [],
	agreementNotificationsAmount: null,
	agreementNotificationsPagination: null,
};
const mutations = {
	SET_AGREEMENT_NOTIFICATIONS(state, payload) {
		state.agreementNotifications = payload || null;
	},
	SET_AGREEMENT_NOTIFICATION(state, payload) {
		state.agreementNotification = payload || null;
	},
	SET_AGREEMENT_NOTIFICATIONS_AMOUNT(state, payload) {
		state.agreementNotificationsAmount = payload || null;
	},
	SET_AGREEMENT_NOTIFICATIONS_PAGINATION(state, payload) {
		state.agreementNotificationsPagination = payload || null;
	},
};

const actions = {
	async saveNotificationRead(context, data) {
		try {
			const response = await notificationService.saveNotificationRead(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async readNotificationUsers(context, data) {
		try {
			const response = await notificationService.readNotificationUsers(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async createNotificationUser(context, data) {
		try {
			const response = await notificationService.createNotificationUser(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteNotificationUser(context, data) {
		try {
			const response = await notificationService.deleteNotificationUser(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async readNotificationSettings(context, data) {
		try {
			const response = await notificationService.readNotificationSettings(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateNotificationSettings(context, data) {
		try {
			const response = await notificationService.updateNotificationSettings(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	// async createAgreementNotification(context, data) {
	// 	try {
	// 		const response = await agreementService.createAgreementNotification(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async listAgreementNotification({ commit }, data) {
	// 	try {
	// 		commit('SET_AGREEMENT_NOTIFICATIONS', []);
	// 		commit('SET_AGREEMENT_NOTIFICATIONS_PAGINATION', null);
	// 		const response = await agreementService.listAgreementNotification(data);
	// 		commit('SET_AGREEMENT_NOTIFICATIONS', response?.data || []);
	// 		commit(
	// 			'SET_AGREEMENT_NOTIFICATIONS_PAGINATION',
	// 			response?.pagination || null
	// 		);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async updateAgreementNotification(context, data) {
	// 	try {
	// 		const response = await agreementService.updateAgreementNotification(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async readAgreementNotification({ commit }, data) {
	// 	try {
	// 		commit('SET_AGREEMENT_NOTIFICATION', null);
	// 		const response = await agreementService.readAgreementNotification(data);
	// 		commit('SET_AGREEMENT_NOTIFICATION', response?.data || null);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async getNotificationAmount({ commit }, data) {
	// 	try {
	// 		commit('SET_AGREEMENT_NOTIFICATIONS_AMOUNT', null);
	// 		const response = await agreementService.listAgreementNotification(data);
	// 		const amount = Array.isArray(response?.data) ? response?.data.length : null;
	// 		commit('SET_AGREEMENT_NOTIFICATIONS_AMOUNT', amount);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	cleanNotifications({ commit }) {
		commit('SET_AGREEMENT_NOTIFICATIONS', []);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
