import { http } from '@/store/api/http-common.js';

const saveNotificationRead = async (payload) => {
	try {
		const { data } = await http.post('agreement-notification/read', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const readNotificationUsers = async (params) => {
	try {
		const { data } = await http.get('agreement-user', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createNotificationUser = async (payload) => {
	try {
		const { data } = await http.post('agreement-user', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteNotificationUser = async ({ agreementId }) => {
	try {
		const { data } = await http.delete(`agreement-user/${agreementId}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 * NotificationSettings
 */

const readNotificationSettings = async (params) => {
	try {
		const { data } = await http.get('agreement-setting', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateNotificationSettings = async (payload) => {
	try {
		const { data } = await http.put(
			'agreement-setting/days-notification-contract-expire',
			payload
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	saveNotificationRead,
	readNotificationUsers,
	createNotificationUser,
	deleteNotificationUser,
	readNotificationSettings,
	updateNotificationSettings,
};

// const createAgreementNotification = async (payload) => {
// 	try {
// 		const { data } = await http.post('agreement-notification', payload);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const listAgreementNotification = async (params) => {
// 	try {
// 		const { data } = await http.get('agreement-notification', { params });
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const updateAgreementNotification = async (params) => {
// 	try {
// 		const url = '/agreement-notification/' + params.agreementId;
// 		delete params.agreementId;
// 		const { data } = await http.put(url, params);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };

// const readAgreementNotification = async (id) => {
// 	try {
// 		const { data } = await http.get(`agreement-notification/${id}`);
// 		return data || null;
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// };
