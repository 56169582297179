import { http } from '../../../api/http-common';

const listDocumentType = async (params) => {
	try {
		const { data } = await http.get('document-type', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getTemplate = async (id) => {
	try {
		const { data } = await http.get(`document-type/${id}/template`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateSheetAddendum = async (payload) => {
	try {
		const { id, body } = payload;
		const { data } = await http.put(`contract-document/${id}`, body);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { listDocumentType, getTemplate, updateSheetAddendum };
