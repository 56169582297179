import * as agreementService from './service/agreement.service';

const state = {
	documentTypes: [],
	agreements: [],
	agreementsPagination: null,
	template: null,
	agreement: null,
	loading_agreement: false,
	agreementNotification: {},
	agreementNotifications: [],
	agreementNotificationsAmount: null,
	agreementNotificationsPagination: null,
	flagRelevantChanges: false,
};
const mutations = {
	SET_DOCUMENT_TYPES(state, payload) {
		state.documentTypes = payload || null;
	},
	SET_TEMPLATE(state, payload) {
		state.template = payload || null;
	},
	SET_AGREEMENTS(state, payload) {
		state.agreements = payload || null;
	},
	SET_AGREEMENTS_PAGINATION(state, payload) {
		state.agreementsPagination = payload || null;
	},
	SET_AGREEMENT(state, payload) {
		state.agreement = payload || null;
	},
	SET_LOADING_AGREEMENT(state, payload) {
		state.loading_agreement = payload || null;
	},
	SET_AGREEMENT_NOTIFICATIONS(state, payload) {
		state.agreementNotifications = payload || null;
	},
	SET_AGREEMENT_NOTIFICATION(state, payload) {
		state.agreementNotification = payload || null;
	},
	SET_AGREEMENT_NOTIFICATIONS_AMOUNT(state, payload) {
		state.agreementNotificationsAmount = payload || null;
	},
	SET_AGREEMENT_NOTIFICATIONS_PAGINATION(state, payload) {
		state.agreementNotificationsPagination = payload || null;
	},
	SET_FLAG_RELEVANT_CHANGES(state, payload) {
		state.flagRelevantChanges = payload || false;
	},
};

const actions = {
	async listDocumentType({ commit }, data) {
		try {
			commit('SET_DOCUMENT_TYPES', null);
			const response = await agreementService.listDocumentType(data);
			commit('SET_DOCUMENT_TYPES', response?.agreementTypes || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getTemplate({ commit }, id) {
		try {
			commit('SET_TEMPLATE', null);
			const response = await agreementService.getTemplate(id);
			commit('SET_TEMPLATE', response?.data || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async createAgreement(context, data) {
		try {
			const response = await agreementService.createAgreement(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async createAgreementWord(context, data) {
		try {
			const response = await agreementService.createAgreementWord(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateAgreement(context, data) {
		try {
			const response = await agreementService.updateAgreement(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async searchAgreement({ commit }, data) {
		try {
			commit('SET_AGREEMENTS', []);
			commit('SET_AGREEMENT', null);
			commit('SET_AGREEMENTS_PAGINATION', null);
			const response = await agreementService.searchAgreement(data);
			commit('SET_AGREEMENTS', response?.agreements || []);
			commit('SET_AGREEMENTS_PAGINATION', response?.pagination || []);

			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getAgreement({ commit }, id) {
		try {
			commit('SET_LOADING_AGREEMENT', true);
			commit('SET_AGREEMENT', null);
			const response = await agreementService.getAgreement(id);
			commit('SET_AGREEMENT', response?.data || null);
			commit('SET_FLAG_RELEVANT_CHANGES', response?.data?.flag_relevant_changes);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		} finally {
			commit('SET_LOADING_AGREEMENT', false);
		}
	},

	async readAgreementDocument(context, payload) {
		try {
			const response = await agreementService.readAgreementDocument(payload);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async readAgreementWord(context, payload) {
		try {
			const response = await agreementService.readAgreementWord(payload);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getFile(context, id) {
		try {
			const response = await agreementService.getFile(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getDocumentToken(context, id) {
		try {
			const response = await agreementService.getDocumentToken(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async readAgreements({ commit }, data) {
		try {
			commit('SET_AGREEMENTS', []);
			const response = await agreementService.readAgreements(data);
			commit('SET_AGREEMENTS', response?.agreements || []);
			commit('SET_AGREEMENTS_PAGINATION', response?.pagination || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async createAgreementNotification(context, data) {
		try {
			const response = await agreementService.createAgreementNotification(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAgreementNotification({ commit }, data) {
		try {
			commit('SET_AGREEMENT_NOTIFICATIONS', []);
			commit('SET_AGREEMENT_NOTIFICATIONS_PAGINATION', null);
			const response = await agreementService.listAgreementNotification(data);
			commit('SET_AGREEMENT_NOTIFICATIONS', response?.data || []);
			commit(
				'SET_AGREEMENT_NOTIFICATIONS_PAGINATION',
				response?.pagination || null
			);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateAgreementNotification(context, data) {
		try {
			const response = await agreementService.updateAgreementNotification(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async readAgreementNotification({ commit }, data) {
		try {
			commit('SET_AGREEMENT_NOTIFICATION', null);
			const response = await agreementService.readAgreementNotification(data);
			commit('SET_AGREEMENT_NOTIFICATION', response?.data || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getNotificationAmount({ commit }, data) {
		try {
			commit('SET_AGREEMENT_NOTIFICATIONS_AMOUNT', null);
			const response = await agreementService.listAgreementNotification(data);
			const amount = Array.isArray(response?.data) ? response?.data.length : null;
			commit('SET_AGREEMENT_NOTIFICATIONS_AMOUNT', amount);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async readApproverUsers(context, data) {
		try {
			const response = await agreementService.readApproverUsers(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async preApprovedNotification(context, data) {
		try {
			const response = await agreementService.preApprovedNotification(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async setFlagRelevantChanges({ commit }, data) {
		commit('SET_FLAG_RELEVANT_CHANGES', data);
	},

	cleanDocumentType({ commit }) {
		commit('SET_DOCUMENT_TYPES', []);
	},

	cleanTemplate({ commit }) {
		commit('SET_TEMPLATE', null);
	},

	cleanAgreements({ commit }) {
		commit('SET_AGREEMENTS', []);
		commit('SET_AGREEMENTS_PAGINATION', null);
	},

	cleanAgreement({ commit }) {
		commit('SET_AGREEMENT', null);
	},

	cleanNotifications({ commit }) {
		commit('SET_AGREEMENT_NOTIFICATIONS', []);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
