import { http } from '../../../api/http-common';

const saveResponsible = async (payload) => {
	try {
		const { data } = await http.post('responsible', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateResponsible = async (payload) => {
	try {
		const { body, id } = payload;
		const { data } = await http.put(`responsible/${id}`, body);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteResponsible = async (id) => {
	try {
		const { data } = await http.delete(`responsible/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listResponsible = async (params) => {
	try {
		const { data } = await http.get('responsible', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { saveResponsible, updateResponsible, deleteResponsible, listResponsible };
