import { http } from '@/store/api/http-common.js';

const listDocumentType = async (params) => {
	try {
		const { data } = await http.get('agreement-type', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getTemplate = async (id) => {
	try {
		const { data } = await http.get(`/agreement-type/${id}/template`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createAgreement = async (payload) => {
	try {
		const { data } = await http.post('agreement', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const searchAgreement = async (params) => {
	try {
		const { data } = await http.get('agreement/search', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateAgreement = async (payload) => {
	try {
		const { data } = await http.put(
			`agreement/${payload.agreement_id}/field-agreement`,
			payload
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getAgreement = async (id) => {
	try {
		const { data } = await http.get('agreement/' + id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getFile = async (id) => {
	try {
		const { data } = await http.get(`agreement/${id}/download`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getDocumentToken = async (id) => {
	try {
		const { data } = await http.post(`agreement/${id}/download/token`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const readAgreementWord = async (params) => {
	try {
		const { data } = await http.get(`agreement-word/${params.agreementWordId}`, {
			params,
		});
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const readAgreementDocument = async (params) => {
	try {
		const { data } = await http.get(`agreement/${params?.agreementId}`, { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createAgreementWord = async (payload) => {
	try {
		const { data } = await http.post('agreement-word', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const readAgreements = async (params) => {
	try {
		const { data } = await http.get('agreement', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createAgreementNotification = async (payload) => {
	try {
		const { data } = await http.post('agreement-notification', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listAgreementNotification = async (params) => {
	try {
		const { data } = await http.get('agreement-notification', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateAgreementNotification = async (params) => {
	try {
		const url = '/agreement-notification/' + params.agreementId;
		delete params.agreementId;
		const { data } = await http.put(url, params);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const readAgreementNotification = async (id) => {
	try {
		const { data } = await http.get(`agreement-notification/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const readApproverUsers = async (params) => {
	try {
		const { data } = await http.get('/agreement-user/approvers', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const preApprovedNotification = async ({ agreementId, form }) => {
	try {
		const { data } = await http.put(`agreement-notification/${agreementId}`, form);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listAnnexesByType = async (params) => {
	try {
		const { data } = await http.get('/agreement-annex-type', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listAgreementAnnex = async (params) => {
	try {
		const { data } = await http.get('/agreement-required-annex', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateAgreementAnnex = async (id, payload) => {
	try {
		const { data } = await http.put(`/agreement-required-annex/${id}`, payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createAgreementAnnex = async (payload) => {
	try {
		const { data } = await http.post(`/agreement-annex/`, payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteAgreementAnnexFile = async (id) => {
	try {
		const { data } = await http.delete(`/agreement-annex/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

// TODO: delete createSendContract

const listAgreementObservations = async (params) => {
	try {
		const { data } = await http.get('agreement-observation', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listAgreementApprover = async (params) => {
	try {
		const { data } = await http.get('agreement-approver', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

/**
 *New
 */

const createAssignedSupplier = async (params) => {
	try {
		const { header, body } = params;
		const { data } = await http.put(
			`agreement/set-provider/${header?.agreement_id}`,
			body
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const createSendContract = async (params) => {
	try {
		const { header, body } = params;
		const url = `agreement/send-agreement/${header?.agreement_id}`;
		const { data } = await http.put(url, body);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const replyAgreementNotification = async (id, payload) => {
	try {
		const { data } = await http.put(`agreement-notification/reply/${id}`, payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteAgreement = async (id) => {
	try {
		const { data } = await http.delete(`/agreement/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	listDocumentType,
	getTemplate,
	createAgreement,
	updateAgreement,
	searchAgreement,
	getAgreement,
	getFile,
	getDocumentToken,
	createAgreementWord,
	readAgreementWord,
	readAgreements,
	createAgreementNotification,
	listAgreementNotification,
	updateAgreementNotification,
	readAgreementNotification,
	readAgreementDocument,
	readApproverUsers,
	preApprovedNotification,
	listAnnexesByType,
	listAgreementAnnex,
	updateAgreementAnnex,
	createAgreementAnnex,
	deleteAgreementAnnexFile,
	createSendContract,
	replyAgreementNotification,
	listAgreementObservations,
	listAgreementApprover,
	createAssignedSupplier,
	deleteAgreement,
};
