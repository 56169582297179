import { http } from '../../../api/http-common';

// USERS
const usersActive = async (params) => {
	try {
		const { data } = await http.get('user', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getManagements = async (params) => {
	try {
		const { data } = await http.get('cost-center/' + params?.user_id);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const searchUsers = async (params) => {
	try {
		const { data } = await http.get('user/search', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listProvider = async (params) => {
	try {
		const { data } = await http.get('agreement-user/user-providers', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const listUserApprovers = async (params) => {
	try {
		const { data } = await http.get('agreement-user/user-approvers', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { usersActive, getManagements, searchUsers, listProvider, listUserApprovers };
