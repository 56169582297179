import * as ownerService from './service/owner.service';

const state = {
	owners: [],
	pagination: null,
};
const mutations = {
	SET_OWNERS(state, payload) {
		state.owners = payload || null;
	},
};

const actions = {
	async listOwners({ commit }, data) {
		try {
			commit('SET_OWNERS', null);
			const response = await ownerService.listOwners(data);
			commit('SET_OWNERS', response?.owners || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveOwner(context, data) {
		try {
			const response = await ownerService.saveOwner(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	// async saveAssignment(context, data) {
	// 	try {
	// 		const response = await assignmentService.saveAssignment(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async updateAssignment(context, data) {
	// 	try {
	// 		const response = await assignmentService.updateAssignment(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async listAssignment({ commit }, data) {
	// 	try {
	// 		commit('SET_ASSIGNMENTS', null);
	// 		const response = await assignmentService.listAssignment(data);
	// 		const assignments = Array.isArray(response?.data) ? response?.data[0] : null;
	// 		commit('SET_ASSIGNMENTS', assignments || null);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// cleanAssignment({ commit }) {
	// 	commit('SET_ASSIGNMENTS', null);
	// },
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
