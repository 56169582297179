import * as documentService from './service/document.service';

const state = {
	agreementDocument: {},
	agreementDocuments: [],
	agreementDocumentsPagination: null,
};
const mutations = {
	SET_AGREEMENT_DOCUMENT(state, payload) {
		state.agreementDocument = payload || null;
	},
	SET_AGREEMENT_DOCUMENTS(state, payload) {
		state.agreementDocuments = payload || null;
	},
	SET_AGREEMENT_DOCUMENTS_PAGINATION(state, payload) {
		state.agreementDocumentsPagination = payload || null;
	},
};

const actions = {
	async createAgreementDocument(context, data) {
		try {
			const response = await documentService.createAgreementDocument(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAgreementDocument({ commit }, data) {
		try {
			const response = await documentService.listAgreementDocument(data);
			commit('SET_AGREEMENT_DOCUMENTS', response?.data || []);
			commit('SET_AGREEMENT_DOCUMENTS_PAGINATION', response?.pagination || null);
			return { ok: true, response };
		} catch (error) {
			commit('SET_AGREEMENT_DOCUMENTS', []);
			commit('SET_AGREEMENT_DOCUMENTS_PAGINATION', null);
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteAgreementDocument(context, data) {
		try {
			const response = await documentService.deleteAgreementDocument(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanAgreementDocuments({ commit }) {
		commit('SET_AGREEMENT_DOCUMENTS', []);
		commit('SET_AGREEMENT_DOCUMENTS_PAGINATION', []);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
