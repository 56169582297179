import * as contractService from './service/contract.service';

const state = {
	contract: null,
	contracts: [],
	contractsPagination: null,
	contractsReport:[],
	contractsReportPagination : null
};
const mutations = {
	SET_CONTRACT(state, payload) {
		state.contract = payload || null;
	},

	SET_CONTRACTS(state, payload) {
		state.contracts = payload || null;
	},

	SET_CONTRACTS_PAGINATION(state, payload) {
		state.contractsPagination = payload || null;
	},

	SET_CONTRACTS_REPORT(state, payload) {
		state.contractsReport = payload || null;
	},

	SET_CONTRACTS_REPORT_PAGINATION(state, payload) {
		state.contractsReportPagination = payload || null;
	},
};

const actions = {
	async uploadFile(context, data) {
		try {
			const response = await contractService.uploadFile(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveContract(context, data) {
		try {
			const response = await contractService.saveContract(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async getContract({ commit }, id) {
		try {
			commit('SET_CONTRACT', null);
			const response = await contractService.getContract(id);
			commit('SET_CONTRACT', response?.data || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveContractDocument(context, data) {
		try {
			const response = await contractService.saveContractDocument(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	//
	async saveUploadContract(context,data){
		try {
			const response = await contractService.saveUploadFileDocument(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async registerContractReport(context,data){
		try {
			const response = await contractService.saveContractReport(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async listAllContractReport({ commit }, data) {
        try {
			commit('SET_CONTRACTS_REPORT', null);
			commit('SET_CONTRACTS_REPORT_PAGINATION', null);
            const response = await contractService.getContractReport(data);
            commit('SET_CONTRACTS_REPORT', response.data);
			commit('SET_CONTRACTS_REPORT_PAGINATION', response.pagination);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || 'Error en el servicio' };
        }
    },

	async getContractReportFileSigned(__, id) {
		try {
			const response = await contractService.getSignedFileUrl(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async signedFileUrl(__, data) {
		try {
			const response = await contractService.signedFile(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},
	//

	async saveVersion(context, data) {
		try {
			const response = await contractService.saveVersion(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteVersion(context, id) {
		try {
			const response = await contractService.deleteVersion(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveComment(context, data) {
		try {
			const response = await contractService.saveComment(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async saveAccessDirect(context, data) {
		try {
			const response = await contractService.saveAccessDirect(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateContractSheet(context, data) {
		try {
			const response = await contractService.updateContractSheet(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async searchContract({ commit }, params) {
		try {
			commit('SET_CONTRACTS', null);
			commit('SET_CONTRACTS_PAGINATION', null);
			const response = await contractService.searchContract(params);
			let contracts = response?.contracts || [];
			// let owner = { ...response?.owner } || {};
			// let contracts = response?.owner?.contracts || [];
			// if (owner?.contracts) delete owner.contracts;
			// if (Array.isArray(contracts)) {
			// 	contracts = contracts.map((ct) => {
			// 		return { ...ct, owner };
			// 	});
			// }
			if (response?.owner?.id) {
				contracts = [
					{
						owner: response.owner,
						contracts: response.contracts,
						preview: 'PROFILE',
					},
					...contracts,
				];
			}
			commit('SET_CONTRACTS', contracts || []);
			commit('SET_CONTRACTS_PAGINATION', response?.pagination || null);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async deleteContract(context, id) {
		try {
			const response = await contractService.deleteContract(id);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	async updateContract(context, data) {
		try {
			const response = await contractService.updateContract(data);
			return { ok: true, response };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanContracts({ commit }) {
		commit('SET_CONTRACTS', []);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
