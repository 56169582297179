import * as reportService from './service/report.service';

const state = {
	reports: [],
	reports_pagination: null,
};
const mutations = {
	SET_REPORTS(state, payload) {
		state.reports = payload;
	},

	SET_REPORTS_PAGINATION(state, payload) {
		state.reports_pagination = payload;
	},
};

const actions = {
	// async saveResponsible(context, data) {
	// 	try {
	// 		const response = await reportService.saveResponsible(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async updateResponsible(context, data) {
	// 	try {
	// 		const response = await reportService.updateResponsible(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	// async deleteResponsible(context, data) {
	// 	try {
	// 		const response = await reportService.deleteResponsible(data);
	// 		return { ok: true, response };
	// 	} catch (error) {
	// 		return { ok: false, error: error?.response || 'Error en el servicio' };
	// 	}
	// },

	async listReport({ commit }, data) {
		try {
			const response = await reportService.listReport(data);
			commit('SET_REPORTS', response?.report || []);
			commit('SET_REPORTS_PAGINATION', response?.pagination || null);
			return { ok: true, response: response?.data };
		} catch (error) {
			return { ok: false, error: error?.response || 'Error en el servicio' };
		}
	},

	cleanResponsibles({ commit }) {
		commit('SET_RESPONSIBLES', null);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
