<template>
	<v-app>
		<nav-bar @switchDrawer="activeDrawer" :moduleList="allowedModules" />
		<v-main class="b-grey">
			<router-view />
		</v-main>
		<v-navigation-drawer v-model="drawer" fixed temporary>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title v-if="user">{{ user.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list dense>
				<v-list-group
					v-for="item in allowedModules"
					v-model="item.active"
					:key="item.title"
					:prepend-icon="item.action"
					no-action
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</template>
					<template v-for="child in item.items">
						<v-list-item
							v-if="verifyAccess(child)"
							:key="child.title"
							:to="child.to"
							link
						>
							<v-list-item-content>
								<v-list-item-title>{{ child.title }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-list-group>
			</v-list>
			<template v-slot:append>
				<div class="pa-2">
					<v-btn color="primary" class="text-none" block @click="onLogout()">
						Cerrar Sesión
					</v-btn>
				</div>
			</template>
		</v-navigation-drawer>
		<snack-notification />
	</v-app>
</template>

<script>
import SnackNotification from '../components/global/SnackNotification.vue';
import NavBar from '../components/nav';
export default {
	name: 'DashboardLayout',

	data: () => ({
		drawer: null,
		items: [
			{
				title: 'Legal',
				action: 'mdi-file-sign',
				to: { name: 'ContractHome' },
				moduleName: 'Contrato',
				showInNav: true,
				childRouteNames: [
					'ContractHome',
					'ContractSearch',
					'CreateContract',
					'ContractReport',
					'ContractNotification',
					'documentShow',
				],
				items: [
					{
						title: 'Buscador',
						action: 'mdi-magnify',
						moduleName: 'Contrato',
						pageName: 'buscarContrato',
						to: { name: 'ContractSearch' },
					},
					{
						title: 'Contrato',
						action: 'mdi-cloud-upload-outline',
						moduleName: 'Contrato',
						pageName: 'crearContrato',
						to: { name: 'CreateContract' },
					},
					{
						title: 'Notificaciones',
						action: 'mdi-bell-outline',
						moduleName: 'Contrato',
						pageName: 'listarNotificaciones',
						to: { name: 'ContractNotification' },
					},
					{
						title: 'Reporte',
						moduleName: 'Contrato',
						pageName: 'descargarReporte',
						action: 'mdi-microsoft-excel',
						to: { name: 'ContractReport' },
					},
				],
			},
			{
				title: 'Abastecimiento',
				action: 'mdi-note-plus',
				to: { name: 'AgreementHome' },
				showInNav: true,
				moduleName: 'abastecimiento',
				childRouteNames: [
					'AgreementHome',
					'CreateAgreement',
					'SearchAgreement',
					'AgreementReport',
					'AgreementNotification',
					'AgreementEditor',
				],
				items: [
					{
						title: 'Buscador',
						action: 'mdi-magnify',
						moduleName: 'abastecimiento',
						pageName: 'buscarPrecontrato',
						to: { name: 'SearchAgreement' },
					},
					{
						title: 'Documento',
						action: 'mdi-list-box-outline',
						moduleName: 'abastecimiento',
						pageName: 'crearPrecontrato',
						to: { name: 'CreateAgreement' },
					},
					{
						title: 'Notificaciones',
						action: 'mdi-bell-outline',
						moduleName: 'abastecimiento',
						pageName: 'listarNotificaciones',
						to: { name: 'AgreementNotification' },
					},
					{
						title: 'Reporte',
						action: 'mdi-microsoft-excel',
						moduleName: 'abastecimiento',
						pageName: 'reportePrecontrato',
						to: { name: 'AgreementReport' },
					},
				],
			},
			{
				title: 'Usuario',
				action: 'mdi-account',
				to: '/dashboard/user/permissions',
				showInNav: false,
				childRouteNames: ['UserPermissions', 'UserChangePassword'],
				items: [
					{
						title: 'Permisos',
						action: 'mdi-account',
						to: '/dashboard/user/permissions',
					},
					// {
					// 	title: 'Cambiar Contraseña',
					// 	action: 'mdi-account',
					// 	to: { name: 'UserChangePassword' },
					// },
				],
			},
		],
	}),
	computed: {
		allowedModules() {
			return this.getAllowedModules();
		},
		user() {
			return this.$store.state.auth.user || null;
		},
	},
	methods: {
		activeDrawer() {
			this.drawer = true;
		},
		getInitialName() {
			if (this.user?.name && this.user?.last_name_father) {
				const name = this.user?.name;
				const lastName = this.user?.last_name_father;
				return `${name[0]}${lastName[0]}`;
			}
		},
		onLogout() {
			this.$store.dispatch('auth/logout');
			this.$router.push({ name: 'Login' });
		},
		getAllowedModules() {
			const activities = this.$store.state.auth.activities || [];
			let modulesList = this.items || [];

			modulesList = modulesList.filter((item) => {
				let found = false;
				if (Array.isArray(activities)) {
					found = activities.some((activity) => {
						if (item.moduleName) {
							return activity.module_name == item.moduleName;
						} else {
							return true;
						}
					});
				}
				return found;
			});
			return modulesList;
		},

		verifyAccess({ moduleName = '', pageName = '' }) {
			if (moduleName && pageName) {
				const activities = this.$store.state.auth.activities || [];
				let found = false;

				if (Array.isArray(activities)) {
					found = activities.some((activity) => {
						return (
							activity.module_name == moduleName &&
							activity.page_name == pageName
						);
					});
				}
				return found;
			}
		},
	},
	components: {
		NavBar,
		SnackNotification,
	},
};
</script>
