import { http } from '../../../api/http-common';

const saveContract = async (payload) => {
	try {
		const { data } = await http.post('contract', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveContractDocument = async (payload) => {
	try {
		const { data } = await http.post('contract-document', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveComment = async (payload) => {
	try {
		const { data } = await http.post('contract-comment', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveContractReport = async (payload) => {
	try {
		const { data } = await http.post('contract/contract-report', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getContractReport = async (params) => {
	try {
		const { data } = await http.get('contract/contract-report', { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getSignedFileUrl = async (id) =>{
    try {
        const { data } = await http.get(`contract/contract-report/signed-file-url/${id}`);
        return data || null;
    } catch (error) {
        return Promise.reject(error);
    }
};

const signedFile = async (payload) => {
	try {
		const { data } = await http.post('contract/signed-file-url', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveVersion = async (payload) => {
	try {
		const { data } = await http.post('contract-version', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteVersion = async (id) => {
	try {
		const { data } = await http.delete(`contract-version/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveAccessDirect = async (payload) => {
	try {
		const { data } = await http.post('direct-access', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const uploadFile = async (payload) => {
	try {
		const { data } = await http.post('contract/file/upload', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getContract = async (id) => {
	try {
		const { data } = await http.get(`contract/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const searchContract = async (params) => {
	try {
		const { data } = await http.get(`contract/search`, { params });
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateContractSheet = async (payload) => {
	try {
		const { contract, contractId } = payload;
		const { data } = await http.put(
			`/contract/${contractId}/contract-field`,
			contract
		);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteContract = async (id) => {
	try {
		const { data } = await http.delete(`contract/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const updateContract = async (payload) => {
	try {
		const { id, bodyParams } = payload;
		const { data } = await http.put(`contract/${id}`, bodyParams);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const deleteContractDocument = async (id) => {
	try {
		const { data } = await http.delete(`contract-document/${id}`);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

const saveUploadFileDocument = async (payload) => {
	try {
		const { data } = await http.post('contract/file/upload/v2', payload);
		return data || null;
	} catch (error) {
		return Promise.reject(error);
	}
};

export {
	uploadFile,
	saveContract,
	getContract,
	saveContractDocument,
	searchContract,
	saveVersion,
	deleteVersion,
	saveComment,
	saveAccessDirect,
	updateContractSheet,
	deleteContract,
	updateContract,
	deleteContractDocument,
	saveUploadFileDocument,
	getSignedFileUrl,
	getContractReport,
	saveContractReport,
	signedFile
};
